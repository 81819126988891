import qs from 'qs'

const bulkImportsRequests = {
  getBulkImportsPaginatedRequest: ({ search, sortModel, filterModels, pageNumber, pageSize }) => ({
    url: `import-service/api/bulk-imports`,
    method: 'get',
    params: {
      search,
      sortModel,
      filterModels,
      pageNumber,
      pageSize,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  }),
  getBulkImportByIdRequest: ({ id }) => ({
    url: `import-service/api/bulk-imports/${id}`,
    method: 'get',
  }),
  uploadBulkImportRequest: (data) => ({
    url: `import-service/api/bulk-imports`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  }),
  startBulkImportRequest: ({ importId }) => ({
    url: `import-service/api/bulk-imports/${importId}/start-import`,
    method: 'post',
  }),
  ignoreBulkImportItemRequest: ({ id }) => ({
    url: `import-service/api/bulk-import-items/${id}/ignore`,
    method: 'put',
  }),
}

export default bulkImportsRequests
