import NavbarTab from '@tabeeb/modules/navbar/components/NavbarTab'
import routes from '@tabeeb/routes'

const BulkImportTab = ({ ...rest }) => {
  const display = true

  const props = {
    item: {
      label: 'Bulk import',
      to: routes.bulkImports,
    },
    display,
    ...rest,
  }

  return <NavbarTab {...props} />
}

export default BulkImportTab
